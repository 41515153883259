import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import BlogItem from "../components/blog-item"

const Blog = ({ data }) => {
  let blogs = data.allBlog.edges

  let firstFive = data.allBlog.edges.slice(0, 5)

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="row column">
        <h1 className="headings">Blog</h1>
        <div className="row mb6">
          {/* Main Blog Content  */}

          <div className="medium-9 columns blog-main-content">
            {blogs.map(node => (
              <div key={node.id}>
                <BlogItem {...node.node} />
              </div>
            ))}
          </div>

          {/* Side Bar */}
          <div className="medium-3 columns blog-sidebar">
            <div className="recent-posts">
              <div className="recent-posts--inner">
                <h4 className="headings xshort regular">Recent Posts</h4>

                <ul>
                  {firstFive.map(node => (
                    <li key={node.node.title}>
                      <Link to={`/about-us/blog${node.node.slug}`}>{node.node.title}</Link>
                    </li>
                  ))}                
                </ul>
              </div>
            </div>

            <div className="follow-us">
              <div className="follow-us--inner">
                <h4 className="headings xshort regular">Follow Us</h4>

                <div
                  className="social-links social-links--blog"
                >
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://twitter.com/SurplusSolution"
                      >
                        <i className="icon-twitter"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.facebook.com/pages/Surplus-Solutions-LLC/265971573491744"
                      >
                        <i className="icon-facebook"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.linkedin.com/company/208136"
                      >
                        <i className="icon-linkedin"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.youtube.com/user/SurplusSolutionsLLC"
                      >
                        <i className="icon-youtube-play"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    allBlog(sort: { order: DESC, fields: publishedDate___seconds }) {
      totalCount
      edges {
        node {
          title
          body
          publishedDate {
            seconds
          }
          slug
          imageUrl
          excerpt
        }
      }
    }
  }
`
